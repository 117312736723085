import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/regions");
  }
  get(id) {
    return axios.get(`/regions/${id}`);
  }
  create(data) {
    return axios.post("/regions", data);
  }
  update(id, data) {
    return axios.put(`/regions/${id}`, data);
  }
}
export default new DataService();